import type { CardConfigType } from '../types';

export enum AppCode {
  GOTD = 'gotd',
  GOTW = 'gotw',
  GOTR = 'gotr',
  GOTT = 'gott',
  GOTM = 'gotm',
  POTW = 'potw',
  TOTW = 'totw',
}

export enum AppType {
  GOTD = 'goal-of-the-day',
  GOTW = 'goal-of-the-week',
  GOTR = 'goal-of-the-round',
  GOTT = 'goal-of-the-tournament',
  GOTM = 'goal-of-the-month',
  POTW = 'player-of-the-week',
  TOTW = 'team-of-the-week',
}

export enum CompetitionCode {
  UCL = 'ucl',
  UEL = 'uel',
  WUCL = 'wucl',
  UECL = 'uecl',
  UNL = 'unl',
  EURO2024 = 'euro2024',
  U21 = 'u21',
  WEURO = 'weuro',
  EQ = 'eq',
}

export const Competitions = {
  [CompetitionCode.UCL]: 'uefachampionsleague',
  [CompetitionCode.UEL]: 'uefaeuropaleague',
  [CompetitionCode.WUCL]: 'womenschampionsleague',
  [CompetitionCode.UECL]: 'uefaconferenceleague',
  [CompetitionCode.UNL]: 'uefanationsleague/video',
  [CompetitionCode.EURO2024]: 'euro2024',
  [CompetitionCode.U21]: 'under21/video',
  [CompetitionCode.WEURO]: 'womenseuro',
  [CompetitionCode.EQ]: 'european-qualifiers/video',
};

const generateUrl = (competition: string, appType: AppType) => `${competition}/${appType}/`;

export const cardConfig: CardConfigType = {
  '1': {
    competition: CompetitionCode.UCL,
    data: [
      { type: AppCode.GOTD, url: generateUrl(Competitions[CompetitionCode.UCL], AppType.GOTD) },
      { type: AppCode.GOTR, url: generateUrl(Competitions[CompetitionCode.UCL], AppType.GOTD) },
      { type: AppCode.GOTT, url: generateUrl(Competitions[CompetitionCode.UCL], AppType.GOTD) },
      { type: AppCode.GOTW, url: generateUrl(Competitions[CompetitionCode.UCL], AppType.GOTD) },
      { type: AppCode.POTW, url: generateUrl(Competitions[CompetitionCode.UCL], AppType.POTW) },
      { type: AppCode.TOTW, url: generateUrl(Competitions[CompetitionCode.UCL], AppType.TOTW) },
    ],
  },
  '14': {
    competition: CompetitionCode.UEL,
    data: [
      { type: AppCode.GOTD, url: generateUrl(Competitions[CompetitionCode.UEL], AppType.GOTD) },
      { type: AppCode.GOTR, url: generateUrl(Competitions[CompetitionCode.UEL], AppType.GOTD) },
      { type: AppCode.GOTT, url: generateUrl(Competitions[CompetitionCode.UEL], AppType.GOTW) },
      { type: AppCode.GOTW, url: generateUrl(Competitions[CompetitionCode.UEL], AppType.GOTW) },
      { type: AppCode.POTW, url: generateUrl(Competitions[CompetitionCode.UEL], AppType.POTW) },
      { type: AppCode.TOTW, url: generateUrl(Competitions[CompetitionCode.UEL], AppType.TOTW) },
    ],
  },
  '28': {
    competition: CompetitionCode.WUCL,
    data: [
      { type: AppCode.GOTT, url: generateUrl(Competitions[CompetitionCode.WUCL], AppType.GOTW) },
      { type: AppCode.GOTW, url: generateUrl(Competitions[CompetitionCode.WUCL], AppType.GOTW) },
      { type: AppCode.POTW, url: generateUrl(Competitions[CompetitionCode.WUCL], AppType.POTW) },
      { type: AppCode.TOTW, url: generateUrl(Competitions[CompetitionCode.WUCL], AppType.TOTW) },
    ],
  },
  '2019': {
    competition: CompetitionCode.UECL,
    data: [
      { type: AppCode.GOTD, url: generateUrl(Competitions[CompetitionCode.UECL], AppType.GOTD) },
      { type: AppCode.GOTR, url: generateUrl(Competitions[CompetitionCode.UECL], AppType.GOTD) },
      { type: AppCode.GOTT, url: generateUrl(Competitions[CompetitionCode.UECL], AppType.GOTW) },
      { type: AppCode.GOTW, url: generateUrl(Competitions[CompetitionCode.UECL], AppType.GOTW) },
      { type: AppCode.POTW, url: generateUrl(Competitions[CompetitionCode.UECL], AppType.POTW) },
      { type: AppCode.TOTW, url: generateUrl(Competitions[CompetitionCode.UECL], AppType.TOTW) },
    ],
  },
  '2014': {
    competition: CompetitionCode.UNL,
    data: [
      { type: AppCode.GOTT, url: generateUrl(Competitions[CompetitionCode.UNL], AppType.GOTT) },
      { type: AppCode.GOTR, url: generateUrl(Competitions[CompetitionCode.UNL], AppType.GOTR) },
      { type: AppCode.POTW, url: generateUrl(Competitions[CompetitionCode.UNL], AppType.POTW) },
    ],
  },
  '3': {
    competition: CompetitionCode.EURO2024,
    data: [
      { type: AppCode.GOTT, url: generateUrl(Competitions[CompetitionCode.EURO2024], AppType.GOTT) },
      { type: AppCode.GOTR, url: generateUrl(Competitions[CompetitionCode.EURO2024], AppType.GOTR) },
      { type: AppCode.GOTW, url: generateUrl(Competitions[CompetitionCode.EURO2024], AppType.GOTW) },
      { type: AppCode.GOTM, url: generateUrl(Competitions[CompetitionCode.EURO2024], AppType.GOTM) },
    ],
  },
  '13': {
    competition: CompetitionCode.U21,
    data: [
      { type: AppCode.GOTT, url: generateUrl(Competitions[CompetitionCode.U21], AppType.GOTT) },
      { type: AppCode.GOTR, url: generateUrl(Competitions[CompetitionCode.U21], AppType.GOTR) },
    ],
  },
  '18': {
    competition: CompetitionCode.WEURO,
    data: [
      { type: AppCode.GOTT, url: generateUrl(Competitions[CompetitionCode.WEURO], AppType.GOTT) },
      { type: AppCode.GOTR, url: generateUrl(Competitions[CompetitionCode.WEURO], AppType.GOTR) },
    ],
  },
  '17': {
    competition: CompetitionCode.EQ,
    data: [
      { type: AppCode.GOTT, url: generateUrl(Competitions[CompetitionCode.EQ], AppType.GOTT) },
      { type: AppCode.GOTR, url: generateUrl(Competitions[CompetitionCode.EQ], AppType.GOTR) },
      { type: AppCode.POTW, url: generateUrl(Competitions[CompetitionCode.EQ], AppType.POTW) },
    ],
  },
};

export const typeMap = {
  [AppCode.GOTW]: {
    ROUND: AppCode.GOTR,
    TOURNAMENT: AppCode.GOTT,
    DAY: AppCode.GOTD,
  },
} as const;
